import API from './index'
import { INDEX_APPLY, INDEX_INDUSTRIES, INDEX_REGIONS } from './endpoints'

/**
 * 提交申请
 */
export function apply({
  name,
  phone,
  companyName,
  companyEmail,
  jobTitle,
  industryId,
  regionId,
}: {
  name: string
  phone: string
  companyName: string
  companyEmail: string
  jobTitle: string
  industryId: string
  regionId: string
}) {
  return API.post(INDEX_APPLY, {
    name,
    phone,
    company_name: companyName,
    company_email: companyEmail,
    position: jobTitle,
    industry_id: industryId,
    region_id: regionId,
  })
}

/**
 * 获取企业类型
 */
export function getIndustries() {
  return API.get(INDEX_INDUSTRIES, { useCache: true })
}

/**
 * 获取地区信息
 */
export function getRegions() {
  return API.get(INDEX_REGIONS, { useCache: true })
}
